import React from 'react'

const Stamp = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 96 92"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="bevel"
    >
      <g
        transform="translate(1.000000, 1.000000)"
        stroke="#FF9C71"
        strokeWidth="3"
      >
        <polyline points="28.1578 17.3061 75.9188 17.3061 75.9188 71.7861 17.5978 71.7861 17.5978 17.3061" />
        <path d="M78.319,0 L69.758,0 C69.763,0.108 69.775,0.215 69.775,0.324 C69.775,4.428 66.448,7.755 62.343,7.755 C58.24,7.755 54.913,4.428 54.913,0.324 C54.913,0.215 54.924,0.108 54.929,0 L38.368,0 C38.373,0.108 38.384,0.215 38.384,0.324 C38.384,4.428 35.058,7.755 30.954,7.755 C26.85,7.755 23.523,4.428 23.523,0.324 C23.523,0.215 23.535,0.108 23.54,0 L0,0 L0,21.875 C4.103,21.875 7.43,25.202 7.43,29.306 C7.43,33.41 4.103,36.736 0,36.736 L0,53.265 C4.103,53.265 7.43,56.592 7.43,60.696 C7.43,64.8 4.103,68.126 0,68.126 L0,90.001 L23.523,90.001 C23.523,85.897 26.85,82.57 30.954,82.57 C35.058,82.57 38.384,85.897 38.384,90.001 L54.913,90.001 C54.913,85.897 58.24,82.57 62.343,82.57 C66.448,82.57 69.775,85.897 69.775,90.001 L93.601,90.001 L93.601,68.126 C89.497,68.126 86.17,64.8 86.17,60.696 C86.17,56.592 89.497,53.265 93.601,53.265 L93.601,36.736 C89.497,36.736 86.17,33.41 86.17,29.306 C86.17,25.202 89.497,21.875 93.601,21.875 L93.601,0" />
      </g>
    </g>
  </svg>
)

export default Stamp
