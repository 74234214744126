import styled from 'styled-components'

import { Box } from '../../components/'

export const FormInner = styled(Box)`
  opacity: 1;
  transition: opacity 700ms cubic-bezier(0.4, 0, 0, 1);

  &.processing {
    opacity 0;
  }
`
export const FormWrapper = styled(Box)`
  transition: transform 700ms cubic-bezier(0.4, 0, 0, 1);
  transform: scale(1);
  z-index: 1;

  &.processing {
    transform: scale(0.9);
  }

  &.success {
    transform: scale(0.9) translate3d(100vw, 0, 0);
  }
`
