import React from 'react'

import { FlexCol, H2, H3 } from '../../../components'

const Confirmation = props => (
  <FlexCol
    position="absolute"
    top="50%"
    left="50%"
    style={{ transform: 'translate3d(-50%, -50%, 0)', userSelect: 'none' }}
  >
    <H2
      children={`You're on the list!`}
      textAlign="center"
      color="text.reverse"
    />
    <H3
      children={`We'll keep you updated.`}
      mt={2}
      textAlign="center"
      color="text.reverse"
    />
  </FlexCol>
)

export default Confirmation
