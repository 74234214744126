import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Img from 'gatsby-image'
import { cover } from 'polished'

import {
  SEO,
  Box,
  H2,
  H6,
  StaticHeader,
  Layout,
  Footer,
  FlexGrid,
  Button,
  RichText,
  Text,
  MobileStaticHeader,
  FlexCol,
} from '../../components'

import Form from '../../views/form'

import './styles.css'
import { FontString } from '../../views/floorplans/components/fontString'

class PostPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      featuredBlogs: [],
    }
  }
  componentDidMount() {
    let featuredBlogs = []
    this.props.data.posts.edges.forEach(post => {
      if (
        post.node.categories &&
        post.node.categories[0] &&
        post.node.categories[1] &&
        (post.node.categories[0].name === 'NUDE' ||
          post.node.categories[1].name === 'NUDE') &&
        post.node.featured_media &&
        post.node.featured_media.localFile &&
        featuredBlogs.length < 3 &&
        post.node.slug !== this.props.data.page.slug
      ) {
        featuredBlogs.push(post.node)
      }
    })
    this.setState({ featuredBlogs: featuredBlogs })
  }
  render() {
    return (
      <Layout>
        <SEO
          title={this.props.data.page.title}
          description={this.props.data.page.excerpt}
          canonical={this.props.data.page.link}
        />
        <MobileStaticHeader />
        <StaticHeader />
        <Box position="relative">
          {this.props.data.page.featured_media &&
            this.props.data.page.featured_media.localFile && (
              <Box mt={7}>
                <Img
                  sizes={
                    this.props.data.page.featured_media.localFile
                      .childImageSharp.sizes
                  }
                  style={{ height: '40vh' }}
                />
              </Box>
            )}
          <Box py={7} px={32} bg="#fff">
            <Box width="100%" maxWidth={920} mx="auto">
              <H2 children={this.props.data.page.title} is="h1" />
              {this.props.data.page.date && (
                <H6
                  children={moment(this.props.data.page.date).format(
                    'MMMM DD, YYYY'
                  )}
                  mt={2}
                />
              )}
              <Box is="article" mt={7}>
                <RichText
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.page.content,
                  }}
                />
              </Box>
              <Box mt={7}>
                <FlexGrid gutterX={[3]} gutterY={[2]}>
                  <Box>
                    {typeof window !== 'undefined' && (
                      <Button
                        children={`Share on Facebook`}
                        appearance="reverse"
                        is="a"
                        target="_blank"
                        href={
                          'https://www.facebook.com/sharer/sharer.php?u=' +
                          encodeURIComponent(window.location.href)
                        }
                      />
                    )}
                  </Box>
                  <Box>
                    {typeof window !== 'undefined' && (
                      <Button
                        children={`Share on Twitter`}
                        appearance="reverse"
                        is="a"
                        target="_blank"
                        href={
                          'https://twitter.com/intent/tweet?text=' +
                          window.location.href
                        }
                      />
                    )}
                  </Box>
                </FlexGrid>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box px={[5, 7]} py={[7, 8]} bg="bg.wash">
          <Box width="100%" maxWidth={1400} mx="auto">
            <FlexGrid gutterX={[5, 5, 5, 6]} gutterY={[5]}>
              {this.state.featuredBlogs.map((blogPost, index) => (
                <FlexCol width={[1, 1 / 2, 1 / 2, 1 / 3]} key={blogPost.slug}>
                  <Box height="100%" bg="bg.default">
                    {blogPost.featured_media &&
                      blogPost.featured_media.localFile && (
                        <Box position="relative" pb="50%">
                          <Link to={'/blog/' + blogPost.slug + '/'}>
                            <Img
                              sizes={
                                blogPost.featured_media.localFile
                                  .childImageSharp.sizes
                              }
                              style={{ ...cover(), height: '100%' }}
                            />
                          </Link>
                        </Box>
                      )}
                    <Box p={4}>
                      {/* {blogPost.date && (
                  <H6
                    children={moment(blogPost.date).format('MMMM DD, YYYY')}
                    mt={2}
                  />
                )} */}
                      <H2>
                        <Link
                          children={blogPost.title}
                          to={'/blog/' + blogPost.slug + '/'}
                        />
                      </H2>
                      {blogPost.excerpt && (
                        <Text
                          dangerouslySetInnerHTML={{ __html: blogPost.excerpt }}
                          mt={3}
                        />
                      )}
                    </Box>
                  </Box>
                </FlexCol>
              ))}
            </FlexGrid>
          </Box>
        </Box>
        <Box position="relative" height={['auto', '100vh']}>
          <style jsx>{`
            ${FontString};
          `}</style>
          <Form />
        </Box>
        <Footer />
      </Layout>
    )
  }
}

export default PostPage

export const query = graphql`
  query PostQuery($id: String!) {
    page: wordpressPost(id: { eq: $id }) {
      slug
      link
      featured_media {
        source_url
        localFile {
          childImageSharp {
            sizes(maxWidth: 1900, quality: 90) {
              ...GatsbyImageSharpSizes_withWebp
            }
          }
        }
      }
      date
      categories {
        name
      }
      content
      title
      excerpt
    }
    posts: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "NUDE" } } } }
    ) {
      edges {
        node {
          slug
          date
          featured_media {
            source_url
            localFile {
              childImageSharp {
                sizes(maxWidth: 1900, quality: 90) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
          }
          categories {
            name
          }
          title
          excerpt
        }
      }
    }
  }
`
