import React from 'react'
import { navigate } from '@reach/router'

import {
  Box,
  Button,
  Checkbox,
  FlexCol,
  H2,
  Text,
  H3,
  Input,
  PhoneNumberInput,
  Select,
  Footer,
} from '../../components'

import Stamp from './components/stamp'

import { FormWrapper, FormInner } from './style'
import Confirmation from './components/confirmation'

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      botFieldEntered: false,
      botField: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      unitType: '',
      priceRange: '',
      precious: '',
      authorized: true,
    }
  }

  componentDidMount = () => {
    window.LassoCRM = window.LassoCRM || {}
    ;(function(ns) {
      ns.tracker = new window.LassoAnalytics('LAS-515532-07')
    })(window.LassoCRM)
    try {
      window.LassoCRM.tracker.setTrackingDomain('//app.lassocrm.com')
      window.LassoCRM.tracker.init() // initializes the tracker
      window.LassoCRM.tracker.track() // track() records the page visit with the current page title, to record multiple visits call repeatedly.
      window.LassoCRM.tracker.patchRegistrationForms()
    } catch (error) {}
  }

  onSubmit = event => {
    event.preventDefault()
    this.props.setProcessing(true)
    this.props.setFailure(false)
    this.props.setSuccess(false)
    if (
      !this.state.botFieldEntered &&
      this.state.lastName !== '' &&
      this.state.firstName !== '' &&
      this.state.email !== '' &&
      this.state.phoneNumber !== ''
    ) {
      fetch(
        '//battistella.ca/nude-cms/submit.php?firstName=' +
          this.state.firstName +
          '&lastName=' +
          this.state.lastName +
          '&phone=' +
          this.state.phoneNumber.replace(/\(|\)/g, '').replace(/-/g, ' ') +
          '&email=' +
          this.state.email +
          '&unitPreference=' +
          this.state.unitType +
          '&priceRange=' +
          this.state.priceRange +
          '&precious=' +
          this.state.precious +
          '&sourceType=' +
          localStorage.getItem('lassoSourceType') +
          '&trackingCode=' +
          window.LassoCRM.tracker.readCookie('ut'),
        {
          method: 'POST',
        }
      )
        .then(response => {
          return response.text()
        })
        .then(body => {
          this.props.setProcessing(false)
          if (body.toString().indexOf(201) !== -1) {
            navigate(
              process.env.NODE_ENV !== 'development'
                ? '/nude/thank-you'
                : '/thank-you'
            )
            this.props.setSuccess(true)
          } else {
            this.props.setSuccess(false)
            this.props.setFailure(true)
          }
        })
    } else {
      this.props.setProcessing(false)
    }
  }

  changeFirstName = event => {
    this.setState({ firstName: event.target.value })
  }

  changeLastName = event => {
    this.setState({ lastName: event.target.value })
  }

  changePhone = event => {
    this.setState({ phoneNumber: event.target.value })
  }

  changeEmail = event => {
    this.setState({ email: event.target.value })
  }

  changePrecious = event => {
    this.setState({ precious: event.target.value })
  }

  changePrice = event => {
    this.setState({ priceRange: event.target.value })
  }

  changeUnit = event => {
    this.setState({ unitType: event.target.value })
  }

  changeBotField = event => {
    this.setState({ botFieldEntered: true, botField: event.target.value })
  }

  toggleAuthorize = event => {
    this.setState({ authorized: !this.state.authorized })
    event.stopPropagation()
    event.preventDefault()
  }

  render() {
    return (
      <Box
        height="100%"
        bg="brand.salmon"
        id={
          process.env.NODE_ENV !== 'development'
            ? '/nude/register'
            : '/register'
        }
      >
        <Box position="relative" height="100%">
          <Confirmation />
          <FlexCol
            justifyContent="center"
            alignItems="center"
            height="100%"
            pt={[24, null, null, 64]}
            pb={[24, null, null, 0]}
            px={[12, null, null, 0]}
          >
            <FormWrapper
              position="relative"
              width={1 / 1}
              maxWidth={920}
              bg="bg.default"
              className={
                this.props.processing
                  ? 'processing'
                  : this.props.success
                  ? 'success'
                  : ''
              }
            >
              <Box
                position="absolute"
                top={[32, 56]}
                right={[24, 56]}
                size={[64, 80]}
              >
                <Stamp />
              </Box>
              <Box
                is="form"
                id="contact-form"
                onSubmit={this.onSubmit}
                px={[24, null, null, 96]}
                pt={112}
                pb={72}
              >
                <FormInner
                  className={
                    this.props.processing || this.props.success
                      ? 'processing'
                      : ''
                  }
                >
                  <input
                    type="hidden"
                    name="domainAccountId"
                    value="LAS-515532-07"
                  />
                  <input type="hidden" name="guid" value="" />
                  <H2 children={`Hello,`} />
                  <Box is="fieldset" m={0} mt={3} mb={5} p={0} border="none">
                    <H3
                      children={`I,`}
                      display="inline"
                      lineHeight={1.5}
                      style={{ verticalAlign: 'middle' }}
                    />
                    <Input
                      width={160}
                      value={this.state.firstName}
                      onChange={this.changeFirstName}
                      type="text"
                      name="firstname"
                      label="First Name"
                      uid="firstname"
                      placeholder="Joan"
                      required
                      style={{ verticalAlign: 'middle' }}
                    />
                    <Input
                      width={160}
                      value={this.state.lastName}
                      onChange={this.changeLastName}
                      type="text"
                      name="lastname"
                      label="Last Name"
                      uid="lastname"
                      placeholder="Smith"
                      required
                      style={{ verticalAlign: 'middle' }}
                    />
                    <H3
                      children={`, want to be contacted by email at`}
                      display="inline"
                      lineHeight={1.5}
                      style={{ verticalAlign: 'middle' }}
                    />
                    <Input
                      width={[240, 288]}
                      value={this.state.email}
                      onChange={this.changeEmail}
                      type="email"
                      name="Email"
                      label="Email"
                      placeholder="joan.smith@yahoo.com"
                      required
                      style={{ verticalAlign: 'middle' }}
                    />
                    <H3
                      children={`and by text at`}
                      display="inline"
                      lineHeight={1.5}
                      style={{ verticalAlign: 'middle' }}
                    />
                    <PhoneNumberInput
                      width={192}
                      value={this.state.phoneNumber}
                      onChange={this.changePhone}
                      type="tel"
                      name="Phone"
                      label="Phone Number"
                      placeholder="(403) 555-1234"
                      required
                      style={{ verticalAlign: 'middle' }}
                    />
                    <H3
                      children={`so I can be the first in the know about Nude by Battistella. I am interested in a`}
                      display="inline"
                      lineHeight={1.5}
                      style={{ verticalAlign: 'middle' }}
                    />
                    <Select
                      value={this.state.unitType}
                      onChange={this.changeUnit}
                      appearance="naked"
                      style={{ verticalAlign: 'middle' }}
                      required
                    >
                      <option children="Select One" value="" disabled />
                      <option children="Studio" value="223193" />
                      <option children="One Bedroom" value="223194" />
                      <option children="One + Den" value="223195" />
                      <option children="Two Bedroom" value="223196" />
                    </Select>
                    <H3
                      children={`and would ideally spend`}
                      display="inline"
                      lineHeight={1.5}
                      style={{ verticalAlign: 'middle' }}
                    />
                    <Select
                      value={this.state.priceRange}
                      onChange={this.changePrice}
                      appearance="naked"
                      style={{ verticalAlign: 'middle' }}
                      required
                    >
                      <option children="Select One" value="" disabled />
                      <option children="<$200,000" value="223197" />
                      <option children="$200,000–$250,000" value="223432" />
                      <option children="$250,000–$300,000" value="223198" />
                      <option children="$300,000–$350,000" value="223199" />
                      <option children="$350,000–$400,000" value="223200" />
                      <option children="$400,000–$450,000" value="223201" />
                      <option children=">$450,000" value="223202" />
                    </Select>
                    <H3
                      children={`.`}
                      display="inline"
                      lineHeight={1.5}
                      style={{ verticalAlign: 'middle' }}
                    />
                  </Box>
                  <Button
                    id="sendButton"
                    children={`Send`}
                    width={144}
                    appearance="reverse"
                    disabled={!this.state.authorized}
                  />
                  <Box mt={4}>
                    <Checkbox
                      checked={this.state.authorized}
                      onClick={this.toggleAuthorize}
                    >
                      <Text
                        children={`By checking this box, I authorize Battistella Developments to provide me with information electronically. Battistella Developments will not sell or make your private information to any third party.`}
                        ml="12px"
                        fontSize="10px"
                        fontWeight={500}
                        lineHeight={1.2}
                        letterSpacing="0.04em"
                        style={{ textTransform: 'uppercase' }}
                      />
                    </Checkbox>
                  </Box>
                  {this.props.failure && (
                    <Box my={3}>
                      <H3
                        children={`Something went wrong. Please try again!`}
                        display="inline"
                        lineHeight={1.5}
                        style={{ verticalAlign: 'middle' }}
                      />
                    </Box>
                  )}
                </FormInner>
              </Box>
            </FormWrapper>
          </FlexCol>
          <Box position="absolute" bottom={0} left={0} right={0}>
            {this.props.footer && <Footer />}
          </Box>
        </Box>
      </Box>
    )
  }
}

export default Form
